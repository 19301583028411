import { createApp } from 'vue'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import App from './App.vue'
import router from './router'

router.beforeEach((to, from, next) => {
    document.title = to.meta.title
    next()
  })
createApp(App).use(router).mount('#app')