<template>
    <div class="col-md-1">
        <div class="sidebar">
          <ul>
            <li><a href="#"><img src="/img/sidebar1.png"></a></li>
            <li><a href="#"><img src="/img/sidebar2.png"></a></li>
            <li><a href="#"><img src="/img/sidebar3.png"></a></li>
            <li><a href="#"><img src="/img/sidebar4.png"></a></li>
            <li><a href="#"><img src="/img/sidebar5.png"></a></li>
            <li><a href="#"><img src="/img/sidebar6.png"></a></li>
            <li><a href="#"><img src="/img/sidebar1.png"></a></li>
            <li><a href="#"><img src="/img/sidebar7.png"></a></li>
            <li><a href="#"><img src="/img/sidebar8.png"></a></li>
          </ul>
        </div>
      </div>
</template>