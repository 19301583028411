<template>
    <header>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6">
              <div class="logo">
                <img src="/img/logo.png" alt="logo">
              </div>
            </div>
            <div class="col-md-6">
              <ul>
                <li><a href="#">John Doe <img src="/img/Male_User.png" alt="Male_User"></a></li>
                <li><a href="#"><img src="/img/Menu.png" alt="Menu"></a></li>
              </ul>
            </div>
          </div>
        </div>
      </header>
</template>