import AuthLayout from '@/layouts/AuthLayout.vue'

export default {
  path: '/',
  component: AuthLayout,
  redirect: '/brand/login',
  children: [
    {
      path: '/',
      name: 'Pickl',
      component: () => import('@/views/LandingView.vue'),
      meta: {
          requiresAuth: false,
          title: 'Welcome To Pickl',
          breadcrumb: 'Landing'
      }
    },
    {
      path: '/brand/signup',
      name: 'brand-signup',
      component: () => import('@/views/Brand/SignupView.vue'),
      meta: {
        requiresAuth: false,
        title: 'Signup | Brand',
        breadcrumb: 'Login'
      }
    },
    {
      path: '/brand/who-do-you-want-to-engage',
      name: 'who-do-you-want-to-engage',
      component: () => import('@/views/Brand/WhoToEngageView.vue'),
      meta: {
        requiresAuth: false,
        title: 'Who do you want to engage | Brand',
        breadcrumb: 'Login'
      }
    },
    {
      path: '/brand/want-them-to-do',
      name: 'want-them-to-do',
      component: () => import('@/views/Brand/WantThemToDoView.vue'),
      meta: {
        requiresAuth: false,
        title: 'Want them to do | Brand',
        breadcrumb: 'Login'
      }
    },
  ]
}
